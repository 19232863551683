import React, {Component} from 'react';

import {loadServices} from "../common/Common";

import Partners from "../components/Partners";
import GetInTouch from "../components/GetInTouch";
import Footer from "./Footer";
import '../assets/css/Services.css';

import {NavLink, withRouter} from 'react-router-dom';


class Services extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
        };
    }

    componentDidMount() {
        loadServices().then(data => {
            this.setState({services: data});
        });
    }

    serviceBody = (services) => {
        let body = [];

        for (let i = 0; i < services.length; i += 6) {
            const service1 = services[i];
            const service2 = services[i + 1];
            const service3 = services[i + 2];
            const service4 = services[i + 3];
            const service5 = services[i + 4];
            const service6 = services[i + 5];

            body.push(
                <div className="row service-tiles" key={`row${i / 6}`}>
                  {service1 && (
                    <div className="col-5" key={`col1${i / 3}`}>
                        <div className="service-card-container animate">
                            <div className='gradient'></div>
                            <img src={service1.header_image_url} className="blog-img" alt={service1.name} style={{ width: "100%", height: "500px" }} />
                            <div className="text-capitalize heading service-card-name">
                                <p className='service-tag'>{service1.name}</p>
                                <p className='service-header-para'>{service1.header_paragraph}</p>
                                <div className="read-more-link">
                                    <button className="btn service-nav-btn white-background-button">
                                        <NavLink className="service-nav-link" to={`/service/${service1.slug}`} target="_top">
                                            Call Now
                                        </NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                    <div className="col-7" key={`col2${i / 6}`}>
                        {service2 &&
                            <div className="service-card-container animate">
                                <div className='gradient'></div>
                                <img src={service2.header_image_url} className="blog-img" alt={service2.name} style={{ width: "100%", height: "500px" }} />
                                <div className="text-capitalize heading service-card-name">
                                    <p className='service-tag'>{service2.name}</p>
                                    <p className='service-header-para'>{service2.header_paragraph}</p>
                                    <div className="read-more-link">
                                        <button className="btn service-nav-btn white-background-button">
                                            {service2.is_link ?
                                                <a href={'/contact-us'} target="_blank" rel="noopener noreferrer" className="service-nav-link">
                                                    Call Now
                                                </a>
                                                : <NavLink className="service-nav-link" to={`/service/${service2.slug}`} key={`col2${i / 6}`} target="_top">
                                                    Call Now
                                                </NavLink>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {service3 &&
                        <div className="col-12" key={`col3${i / 6}`}>
                            <div className="service-card-container animate">
                                <div className='gradient'></div>
                                <img src={service3.header_image_url} className="blog-img" alt={service3.name} style={{ width: "100%", height: "500px" }} />
                                <div className="text-capitalize heading service-card-name">
                                    <p className='service-tag'>{service3.name}</p>
                                    <p className='service-header-para'>{service3.header_paragraph}</p>
                                    <div className="read-more-link">
                                        <button className="btn service-nav-btn white-background-button">
                                            {service3.is_link ?
                                                <a href={'/contact-us'} target="_blank" rel="noopener noreferrer" className="service-nav-link">
                                                    Call Now
                                                </a>
                                                : <NavLink className="service-nav-link" to={`/service/${service3.slug}`} target="_top">
                                                    Call Now
                                                </NavLink>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-7" key={`col1${i / 6}`}>
                        {service4 &&
                            <div className="service-card-container animate">
                                <div className='gradient'></div>
                                <img src={service4.header_image_url} className="blog-img" alt={service4.name} style={{ width: "100%", height: "500px" }} />
                                <div className="text-capitalize heading service-card-name">
                                    <p className='service-tag'>{service4.name}</p>
                                    <p className='service-header-para'>{service4.header_paragraph}</p>
                                    <div className="read-more-link">
                                        <button className="btn service-nav-btn white-background-button">
                                            {service4.is_link ?
                                                <a href={'/contact-us'} target="_blank" rel="noopener noreferrer" className="service-nav-link">
                                                    Call Now
                                                </a>
                                                : <NavLink className="service-nav-link" to={`/service/${service4.slug}`} target="_top">
                                                    Call Now
                                                </NavLink>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-5" key={`col2${i / 6}`}>
                        {service5 &&
                            <div className="service-card-container animate">
                                <div className='gradient'></div>
                                <img src={service5.header_image_url} className="blog-img" alt={service5.name} style={{ width: "100%", height: "500px" }} />
                                <div className="text-capitalize heading service-card-name">
                                    <p className='service-tag'>{service5.name}</p>
                                    <p className='service-header-para'>{service5.header_paragraph}</p>
                                    <div className="read-more-link">
                                        <button className="btn service-nav-btn white-background-button">
                                            {service5.is_link ?
                                                <a href={'/contact-us'} target="_blank" rel="noopener noreferrer" className="service-nav-link">
                                                    Call Now
                                                </a>
                                                : <NavLink className="service-nav-link"to={`/service/${service5.slug}`} target="_top">
                                                    Call Now
                                                </NavLink>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {service6 &&
                        <div className="col-12" key={`col3${i / 6}`}>
                            <div className="service-card-container animate">
                                <div className='gradient'></div>
                                <img src={service6.header_image_url} className="blog-img" alt={service6.name} style={{ width: "100%", height: "500px" }} />
                                <div className="text-capitalize heading service-card-name">
                                    <p className='service-tag'>{service6.name}</p>
                                    <p className='service-header-para'>{service6.header_paragraph}</p>
                                    <div className="read-more-link">
                                        <button className="btn service-nav-btn white-background-button">
                                            {service6.is_link ?
                                                <a href={'/contact-us'} target="_blank" rel="noopener noreferrer" className="service-nav-link">
                                                    Call Now
                                                </a>
                                                : <NavLink className="service-nav-link" to={`/service/${service6.slug}`} target="_top">
                                                    Call Now
                                                </NavLink>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        }

        return body;
    }


    render () {
        const { services } = this.state;
        return (
            <div>
                <div className="header-container services-header">
                    <h1 className="heading header-heading-main" id='services-section-main-heading'>
                    Travel With <br/>Confidence
                    </h1>
                </div>
                <div className='container mt-5' id="services">
                    {this.serviceBody(services)}
                </div>
                <div className='get-in-touch-container Margin-remover ' id="getintouch">
                    <GetInTouch/>
                </div>
                <div id="partners">
                    <Partners/>
                </div>
                <div id="footer">
                    <Footer />
                </div>
            </div>
        )
    }
}
export default Services;