import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAlert } from "react-alert";
import { getUser } from "../../auth/Auth";
import { handleDeleteAllCheckBox, handleDeleteCheckBox } from "./Common";
import { deleteRequest, uploadFile } from "../../routes/Routes";
import { loadServiceAreas } from "../../common/Common";

class ServiceAreas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            serviceAreas: [],
            deleteServiceAreas: [],
            selectedServiceArea: null,
        };
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({ user: data });
        }).catch(() => null);
        loadServiceAreas().then(data => {
            this.setState({ serviceAreas: data });
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            serviceAreas,
            deleteServiceAreas,
            selectedServiceArea,
        } = this.state;
        return (
            <div>
                {action === 'Add New Service Areas' || action === 'Edit Service Area' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="row">
                            <div className="form-group col-md-5">
                                <label htmlFor="name" className="form-label">Service Area Name</label>
                                {selectedServiceArea ?
                                    <input className="form-control" type="text" placeholder='Service Area Name' id="name"
                                        required
                                        value={selectedServiceArea.name}
                                        onChange={(event) => this.handleChange(event, 'name')} />
                                    :
                                    <input className="form-control" type="text" placeholder='Service Area Name' id="name"
                                        required />
                                }
                            </div>
                            <div className="form-group col-md-5">
                                <label htmlFor="name" className="form-label">Address</label>
                                {selectedServiceArea ?
                                    <input className="form-control" type="text" placeholder='Address' id="address"
                                        required
                                        value={selectedServiceArea.address}
                                        onChange={(event) => this.handleChange(event, 'address')} />
                                    :
                                    <input className="form-control" type="text" placeholder='Address' id="address"
                                        required />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-5">
                                <label htmlFor="name" className="form-label">Contact Number</label>
                                {selectedServiceArea ?
                                    <input className="form-control" type="text" placeholder='Contact Number' id="contNum"
                                        required
                                        value={selectedServiceArea.phone}
                                        onChange={(event) => this.handleChange(event, 'phone')} />
                                    :
                                    <input className="form-control" type="text" placeholder='Contact Number' id="contNum"
                                        required />
                                }
                            </div>
                            <div className="form-group col-md-5">
                                <label htmlFor="name" className="form-label">Email Address</label>
                                {selectedServiceArea ?
                                    <input className="form-control" type="text" placeholder='Email Address' id="email"
                                        required
                                        value={selectedServiceArea.email}
                                        onChange={(event) => this.handleChange(event, 'email')} />
                                    :
                                    <input className="form-control" type="text" placeholder='Email Address' id="email"
                                        required />
                                }
                            </div>
                        </div>
                        {success &&
                            <div className="alert alert-success" role="alert">
                                {successMessage}
                            </div>}
                        {error &&
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>}
                        <div className="form-row">
                            <button className="btn white-button form-button col mr-3" style={{ marginRight: '5%' }} id=""
                                onClick={this.actionClick}>
                                Cancel
                            </button>
                            <button className="btn red-button form-button col">
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                        aria-hidden="true" />
                                    : null
                                }
                                {action === 'Add New Service Areas' ? 'Add' : 'Save'}
                            </button>
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Service Area Info</h1>,
                        <div key="serviceArea-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'serviceAreaSelectAll'}
                                    checked={serviceAreas?.length === deleteServiceAreas.length}
                                    onChange={() => this.handleDeleteAllCheckBox()} />
                                <label className="custom-control-label" htmlFor={'serviceAreaSelectAll'}>Select All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                    disabled={deleteServiceAreas.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Service Areas"
                                    onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 table-hover admin-table" key="serviceArea-table">
                            <thead>
                                <tr>
                                    <th />
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, serviceArea = null) => {
        if (!serviceArea) event.preventDefault();
        this.setState({
            action: serviceArea ? event : event.target.id,
            selectedServiceArea: serviceArea,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
        });
    };

    formSubmit = (event) => {
        let { user, serviceAreas, action, selectedServiceArea} = this.state,
            endpoint;
        event.preventDefault();
        const data = new FormData();
        if (action === 'Add New Service Areas') {
            data.append('name', event.target.name.value);
            data.append('address', event.target.address.value);
            data.append('contNum', event.target.contNum.value);
            data.append('email', event.target.email.value);
            endpoint = '/api/serviceAreas';
        } else {
            data.append('name', selectedServiceArea.name);
            data.append('address', selectedServiceArea.address);
            data.append('contNum', selectedServiceArea.phone);
            data.append('email', selectedServiceArea.email);
            endpoint = '/api/serviceAreas/update/' + selectedServiceArea.id;
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Service Areas') {
                        serviceAreas.push(response.data.serviceArea);
                    } else {
                        serviceAreas[serviceAreas.indexOf(selectedServiceArea)] = response.data.serviceArea;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        serviceAreas: serviceAreas,
                    });
                }
            } else {
                this.setState({ error: true, errorMessage: 'An error occurred!' });
            }
            this.setState({ loading: false });
        });
    }

    tableBody = () => {
        let body = [],
            { serviceAreas, deleteServiceAreas } = this.state;
        if (serviceAreas) {
            serviceAreas.forEach((serviceArea, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                    id={'serviceAreaDelete' + serviceArea.id}
                                    checked={deleteServiceAreas.includes(serviceArea.id)}
                                    onChange={(event) => this.handleDeleteCheckBox(serviceArea.id)} />
                                <label className="custom-control-label" htmlFor={'serviceAreaDelete' + serviceArea.id} />
                            </div>
                        </td>
                        <td>{serviceArea.name}</td>
                        <td>{serviceArea.address}</td>
                        <td>{serviceArea.phone}</td>
                        <td>{serviceArea.email}</td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Service Area', serviceArea)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let { deleteServiceAreas } = this.state;
        this.setState({ deleteServiceAreas: handleDeleteCheckBox(id, deleteServiceAreas) });
    };

    handleDeleteAllCheckBox() {
        let { deleteServiceAreas, serviceAreas } = this.state;
        this.setState({ deleteServiceAreas: handleDeleteAllCheckBox(deleteServiceAreas, serviceAreas) });
    }

    delete = () => {
        let { user, serviceAreas, deleteServiceAreas } = this.state;
        deleteServiceAreas.forEach((serviceArea, index) => {
            deleteRequest('/api/serviceAreas/' + serviceArea, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deleteServiceAreas.length === index + 1) {
                            this.setState({
                                serviceAreas: serviceAreas.filter(function (serviceArea) {
                                    return !deleteServiceAreas.includes(serviceArea.id);
                                }),
                                deleteServiceAreas: []
                            });
                            this.props.alert.success("Successfully deleted Service Areas!");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let { selectedServiceArea } = this.state;
        selectedServiceArea[key] = event.target.value;
        this.setState({ selectedServiceArea: selectedServiceArea, });
    }
}

export default withAlert()(withRouter(ServiceAreas));
