import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {deleteUser, getUser} from "../auth/Auth";
import {loadServices} from "../common/Common";
import '../assets/css/Header.css';

import logo from '../assets/images/logo.webp';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {showNavigation: false, user: null, services: []};
    }

    componentDidMount() {
        getUser().then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadServices().then(data => {
            const firstThreeServices = data.slice(0, 3);
            this.setState({ services: firstThreeServices });
        }).catch(() => null);
    }

    render() {
        let {user} = this.state;
        return (
            <header className="fixed-top">
                <div className="call-container">
                <a className="text-bar" href="https://g.co/kgs/5Q1ZdJ7" target='_blank'>Visit our latest center at One Galle Face</a>

                </div>
                <nav className="navbar navbar-expand-lg container">
                    <NavLink className="navbar-brand" to="/" key="logo">
                        <img src={logo} alt="Logo" className='logo-SP'/>
                    </NavLink>
                    <button className="navbar-toggler" type="button" onClick={this.showNavigation}>
                        <span className="nav-link menu-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="white"
                             alt="Menu">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"/>
                        </svg>
                        </span>
                    </button>
                    <div className="navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto"/>
                        <ul className="navbar-nav navlinks-container mb-2 mb-lg-0" style={{width: user ? '30%' : '30%'}}>
                            <li className="nav-item" key="home">
                                <NavLink className="nav-link" exact to="/">Home</NavLink>
                            </li>

                            <li className="nav-item dropdown" key="about">
                                <NavLink className="nav-link dropdown-toggle" to="/about-us" id="aboutDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                    About
                                </NavLink>
                                <div className={`dropdown-menu ${this.state.showNavigation ? 'show' : ''}`} aria-labelledby="aboutDropdown"
                                    style={{padding: '10%', textAlign: 'left'}}>
                                    <NavLink className="nav-link" to="/about-us" key="about" style={{marginBottom: '5%', padding: '10%'}}>
                                        About Us
                                    </NavLink>
                                    <NavLink className="nav-link" to="/crew" key="crew" style={{marginBottom: '5%', padding: '10%'}}>
                                        Our Crew
                                    </NavLink>
                                </div>
                            </li>

                            <li className="nav-item dropdown" key="resources">
                                    <NavLink className="nav-link dropdown-toggle" exact to="/" id="aboutDropdown"
                                             role="button" data-toggle="dropdown" aria-haspopup="true"
                                             aria-expanded="false">
                                                Resources
                                    </NavLink>
                                    <div className="dropdown-menu" aria-labelledby="aboutDropdown"
                                         style={{padding: '10%', textAlign: 'left'}}>
                                        <NavLink className="nav-link" to="/blog" key="blogs"
                                                 style={{marginBottom: '5%', padding: '10%'}}>
                                                    Blogs
                                        </NavLink>
                                    </div>
                            </li>
                            <li className="nav-item" key="services">
                                <NavLink className="nav-link" to="/services">Services</NavLink>
                            </li>
                            <li className="nav-item" key="Promotions">
                                <NavLink className="nav-link" to='/promotions'>
                                    Promotions
                                </NavLink>
                            </li>
                            <li className="nav-item" key="contact-us">
                                <NavLink className="hd-contact nav-link" to="/contact-us">Contact Us</NavLink>
                            </li>
                            {user ?
                                <li className="nav-item dropdown" key="user">
                                    <NavLink className="nav-link dropdown-toggle" to="#" id="userDropdown"
                                             role="button" data-toggle="dropdown" aria-haspopup="true"
                                             aria-expanded="false">
                                        Hi, <span className="capital-text">
                                        {user.name}</span>
                                    </NavLink>
                                    <div className="dropdown-menu" aria-labelledby="userDropdown"
                                         style={{padding: '10%', textAlign: 'left'}}>
                                        <NavLink className="nav-link" to="/admin" key="portal"
                                                 style={{marginBottom: '5%', padding: '10%'}}>Admin Panel</NavLink>
                                        <NavLink className="nav-link" to="/login" onClick={this.logout} key="logout"
                                                 style={{marginBottom: '5%', padding: '10%'}}>
                                            Sign out of <span
                                            className="capital-text font-weight-bold">{user.name}</span>
                                        </NavLink>
                                    </div>
                                </li>
                                : null
                            }
                        </ul>
                        <ul className="navbar-nav mx-auto"/>
                        <ul className="navbar-nav" style={{width: '20%', justifyContent: 'center'}}>
                            <li className="nav-item">
                                <NavLink className="nav-link sp-nv-lnk" to={{pathname: "https://facebook.com/driveline.lk"}}
                                    target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                         fill="white" alt="Facebook" id="social-link">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path
                                            d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/>
                                    </svg>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={{pathname: "https://instagram.com/driveline.lk"}}
                                    target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                         fill="white" alt="Instagram">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path
                                            d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"/>
                                    </svg>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={{pathname: "https://www.youtube.com/@drivelinesrilanka5418"}}
                                    target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                         fill="white" alt="Youtube">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path
                                            d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z"/>
                                    </svg>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
                {this.state.showNavigation ?
                    <div id="navigation" className="navigation-overlay black-background">
                        <div className="container">
                            <span className="nav-link menu-icon navigation-close-button"
                                  onClick={this.showNavigation}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                         fill="#DAA676">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path
                                            d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/>
                                  </svg>
                                  </span>
                            <div className="navigation-overlay-content">
                                <div className="d-flex bd-highlight w-100">

                                    <div className="p-2 flex-fill bd-highlight">
                                        <h1 className="heading text-uppercase">The Company</h1>
                                        <NavLink className="nav-link" to="/about-us" onClick={this.showNavigation}>
                                            About Us
                                        </NavLink>
                                        <NavLink className="nav-link" to="/crew" onClick={this.showNavigation}>
                                            The Crew
                                        </NavLink>
                                        {/*<NavLink className="nav-link" to="/csr-projects" onClick={this.showNavigation}>*/}
                                        {/*    CSR Projects*/}
                                        {/*</NavLink>*/}
                                        <NavLink className="nav-link"
                                                 to={{pathname: "https://www.facebook.com/driveline.lk/jobs/"}}
                                            target="_blank" rel="noopener noreferrer" onClick={this.showNavigation}>
                                            Careers
                                        </NavLink>
                                        {/*<NavLink className="nav-link" to="/privacy-policy"*/}
                                        {/*         onClick={this.showNavigation}>*/}
                                        {/*    Privacy Policy*/}
                                        {/*</NavLink>*/}
                                    </div>
                                    <div className="p-2 flex-fill bd-highlight">
                                        <h1 className="heading text-uppercase">Services</h1>
                                        <div className=''>
                                            {this.servicesList()}
                                            <NavLink className="nav-link" to="/services" onClick={this.showNavigation}>View All Services</NavLink>
                                        </div>
                                    </div>
                                    <div className="p-2 flex-fill bd-highlight">
                                        <h1 className="heading text-uppercase">Resources</h1>
                                        <NavLink className="nav-link" to="/blog" onClick={this.showNavigation}>
                                           Blogs
                                        </NavLink>
                                    </div>
                                    <div className="p-2 flex-fill bd-highlight">
                                        <h1 className="heading text-uppercase">Promotions</h1>
                                        <NavLink className="nav-link" to="/promotions" onClick={this.showNavigation}>
                                           View All Promotions
                                        </NavLink>
                                    </div>
                                    <div className="p-2 flex-fill bd-highlight">
                                        <h1 className="heading text-uppercase">Contact Us</h1>
                                        <NavLink className="nav-link" to="/contact-us" onClick={this.showNavigation}>
                                            Contact Us
                                        </NavLink>
                                        <div className="contact-details-container">
                                            <a className='nav-link' href="tel:+94 712 885 885">071 288 5885</a>
                                            <a className='nav-link'
                                               href="mailto:info@driveline.lk">info@driveline.lk</a>
                                            {/*<p onClick={this.handleCall}>071 288 5885</p>*/}
                                            {/*<p>info@driveline.lk</p>*/}
                                        </div>

                                    </div>
                                    {user ?
                                        <div className="p-2 flex-fill bd-highlight">
                                            <h1 className="heading text-uppercase">
                                                Hi, {user.name}
                                            </h1>
                                            <NavLink className="nav-link" to="/admin" onClick={this.showNavigation}>
                                                Admin Panel
                                            </NavLink>
                                            <NavLink className="nav-link" to="/login" onClick={this.logout}>
                                                Sign out of <span
                                                className="text-capitalize font-weight-bold">{user.name}</span>
                                            </NavLink>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="social-media-link-container">
                                    <NavLink className="nav-link" to={{pathname: "https://facebook.com/driveline.lk"}}
                                        target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                             height="24" fill="white" alt="Facebook" className="social-link">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/>
                                        </svg>
                                    </NavLink>
                                    <NavLink className="nav-link" to={{pathname: "https://www.instagram.com/driveline.lk/"}}
                                        target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                             height="24" fill="white" alt="Instagram">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"/>
                                        </svg>
                                    </NavLink>
                                    <NavLink className="nav-link" to={{pathname: "https://www.youtube.com/@drivelinesrilanka5418"}}
                                        target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                             height="24" fill="white" alt="Youtube">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z"/>
                                        </svg>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </header>
        )
    }

    showDropdown = () => {
        this.setState({ showNavigation: true });
    };

    hideDropdown = () => {
        this.setState({ showNavigation: false });
    };

    showNavigation = () => {
        this.setState({showNavigation: !this.state.showNavigation});
    }

    handleCall = () => {
        window.location.href = 'tel:+94 749 2901 290';
    }

    logout = () => {
        deleteUser().then(() => {
            this.setState({user: null});
        }).catch(() => null);
    };

    servicesList = () => {
        let body = [];
        this.state.services.forEach((service, index) => {
            body.push(
                <NavLink className="nav-link text-capitalize" to={"/service/" + service.slug} key={index} target="_top">
                    {service.name}
                </NavLink>
            )
        })
        return body;
    }
}

export default withRouter(Header);