import React, { Component } from 'react';
import { postRequest } from "../routes/Routes";
import Partners from "../components/Partners";
import Footer from "./Footer";
import '../assets/css/ContactUs.css';
import { loadServiceAreas } from "../common/Common";
import location from "../assets/images/location.png";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
        };
    }

    componentDidMount() {
        loadServiceAreas().then(data => {
            this.setState({ serviceArieas: data });
        }).catch(() => null);
    }

    serviceArieasBody = (serviceArieas = []) => { 
        let body = [];
        serviceArieas.forEach((serviceAriea, index) => {
            body.push(
                <div key={index} className="col address-card">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="#DAA676" alt="Location">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                    </svg>
                    <h3 className="heading">{serviceAriea.name}</h3>
                    <p>{serviceAriea.address}</p>
                    <div className="contact-details-container">
                        <p><a href="tel:{serviceAriea.phone}">{serviceAriea.phone}</a></p>
                        <p><a href="mailto:{serviceAriea.email}">{serviceAriea.email}</a></p>
                    </div>
                </div>
            );
        });
        return body;
    }    


    render() {
        let {serviceArieas} = this.state;
        return (
            <div>
                <div className="header-container contact-us-header">
                    <h1 className="heading header-heading" id='contact-us-main-topic'>Contact Us</h1>
                    <div className="container">
                        <p className="container con-p-header-par">
                            A refined drive feel awaits you, Get in touch with us today !
                        </p>
                    </div>
                </div>
                <div id="book-appointment" className="d-flex w-100 contact-us-form-container">
                    <div className="p-2 flex-fill bd-highlight animate gap-3 row cols-2 contact-us-number-container">
                        {/* <div className="col address-card">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="#DAA676" alt="Location">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                            <h3 className="heading">Nawala</h3>
                            <p>No.354, Nawala Road, Rajagiriya</p>
                            <div className="contact-details-container">
                                <p><a href="tel:+94117173460">+94 117 173 460</a></p>
                                <p><a href="mailto:slipnshine@driveline.lk">slipnshine@driveline.lk</a></p>
                            </div>
                        </div>
                        <div className="col address-card">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="#DAA676" alt="Location">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                            <h3 className="heading">Dematagoda</h3>
                            <p>No.279, Baseline Road, Col 09</p>
                            <div className="contact-details-container">
                                <p><a href="tel:+94117173470">+94 117 173 470</a></p>
                                <p><a href="mailto:crown@driveline.lk">crown@driveline.lk</a></p>
                            </div>
                        </div>
                        <div className="col address-card">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="#DAA676" alt="Location">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                            <h3 className="heading">Badulla</h3>
                            <p>No.01, Dewala Veediya, Badulla</p>
                            <div className="contact-details-container">
                                <p><a href="tel:+94117 173464">+94 117 173 464</a></p>
                                <p><a href="mailto:kinsmen@driveline.lk">kinsmen@driveline.lk</a></p>
                            </div>
                        </div>
                        <div className="col address-card">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="#DAA676" alt="Location">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                            <h3 className="heading">Gampola</h3>
                            <p>No.279, Kahatapitiya, Gampola</p>
                            <div className="contact-details-container">
                                <p><a href="tel:+94117173465">+94 117 173 465</a></p>
                                <p><a href="mailto:midland@driveline.lk">midland@driveline.lk</a></p>
                            </div>
                        </div>
                        <div className="col address-card">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="#DAA676" alt="Location">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                            <h3 className="heading">One Galle Face</h3>
                            <p>LK 1A, Central Road, Colombo 00200</p>
                            <div className="contact-details-container">
                                <p><a href="tel:+94716880880">+94 716 880 880</a></p>
                                <p><a href="mailto:info@driveline.lk">info@driveline.lk</a></p>
                            </div>
                        </div> */}
                        {this.serviceArieasBody(serviceArieas)}
                    </div>
                    <div className="p-2 flex-fill bd-highlight mt-5 animate">
                        <h2 className="page-heading c-us-hd">Book Your Appointment</h2>
                        <form onSubmit={this.contactUsFunction}>
                            <div className="form-group form-floating">
                                <input type="text" className="form-control txt" id="name" placeholder="Your Name" required />
                                <label className='lbl' htmlFor="name">Full name</label>
                            </div>
                            <div className="form-group form-floating">
                                <input type="email" className="form-control txt" id="email" placeholder="Email" required />
                                <label className='lbl' htmlFor="email">Email Address</label>
                            </div>
                            <div className="form-group form-floating">
                                <input type="tel" className="form-control txt" id="phone" pattern="[0-9]{10}"
                                    placeholder="Your Phone Number" required />
                                <label className='lbl' htmlFor="phone">Contact Number</label>
                            </div>
                            <div className="form-group form-floating">
                                <textarea className="form-control txt" style={{ height: 200 }} id="message"
                                    placeholder="Message" required />
                                <label className='lbl' htmlFor="message">Message</label>
                            </div>
                            <p className='lbl-para'>
                                By clicking "Submit" I agree to be contacted at the number provided with more information
                                or offers about Driveline products. I understand these calls or texts may use computer-assisted
                                dialing or pre-recorded messages.<br /><br />
                                This consent is not a condition of purchase.
                            </p>
                            {this.state.success &&
                                <div className="alert alert-success" role="alert">
                                    {this.state.successMessage}
                                </div>}
                            {this.state.error &&
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>}
                            <button className="btn contact-submit-button" type="submit" disabled={this.state.loading}>
                                {this.state.loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                        aria-hidden="true" />
                                    : null
                                }
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
                <div id="partners">
                    <Partners />
                </div>
                <div id="footer">
                    <Footer />
                </div>
            </div>
        )
    }

    contactUsFunction = (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        let values = {
            name: event.target.name.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            message: event.target.message.value,
            endpoint: '/api/contact-us'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({ error: true, errorMessage: response.data.message });
                } else {
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                    });
                }
            } else {
                this.setState({ error: true, errorMessage: 'An error occurred!' });
            }
            this.setState({ loading: false });
        });
    }
}

export default ContactUs;
