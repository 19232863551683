import React, {Component, useState} from 'react';
import {NavLink} from 'react-router-dom';


import '../assets/css/Blogs.css';

const truncateWords = (text, numWords) => {
  const words = text.split(' ');
  if (words.length > numWords) {
    return words.slice(0, numWords).join(' ') + '...';
  }
  return text;
};

class Blogs extends Component {
    render() {
        let {blogs} = this.props;
        if (blogs.length > 0) {
            return (
                <div className="blogs-container d-flex justify-content-center">
                    <div className="d-flex bd-highlight row add-sp-top">
                        {this.createBlogRows(blogs)}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    createBlogRows = (blogs) => {
        let rows = [];
        for (let i = 0; i < blogs.length; i += 3) {
            let blogsInRow = blogs.slice(i, i + 3);
            rows.push(
                <div className="row gap-5 mb-4 sp-gap" key={`blogRow-${i / 3}`}>
                    {this.blogsBody(blogsInRow)}
                </div>
            );
        }
        return rows;
    };

    blogsBody = (blogs, first) => {
        let body = [];
        blogs.forEach((blog, index) => {
            let textContent = "No content available";
            let textCreatedDate = "No content available";
            let additionalInfo = "";

            const parsedContent = JSON.parse(blog.content);

            if (parsedContent) {
                additionalInfo = parsedContent.additional_info;

                if (parsedContent.blocks && parsedContent.blocks.length > 0) {
                    const firstBlock = parsedContent.blocks[0];
                    textContent = firstBlock.data ? firstBlock.data.text : firstBlock.text;
                }
            }

            textContent = textContent.length > 40 ? textContent.substring(0, 200) + '...' : textContent;

            const createdAtDate = new Date(blog.created_at);
            textCreatedDate = createdAtDate.toDateString();
            const truncatedTitle = truncateWords(blog.name, 13);

            body.push(
                <div className="blog-container col animate" key={index}>
                <img
                    src={blog.header_image_url}
                    className="blog-page-img"
                    style={{ overflow: "hidden", width: "100%", height: "30vh", objectFit: "cover" }}
                    alt={blog.name}
                />
                <div className="text-capitalize heading blog-card-gray">
                        <h1 className="card-heading text-star">{truncatedTitle}</h1>
                        {blog.is_link ? <p className='blog-para spc-blg-txt'>{additionalInfo}</p> : <p className='blog-para spc-blg-txt'>{textContent}</p> }
                    <div className="d-flex">
                        <button className="btn white-background-button blog-button" style={{ width: '200px' }}>
                        {blog.is_link ? (
                                    <a href={blog.link} target="_blank" rel="noopener noreferrer" className="nav-link">
                            Read More
                        </a>
                        ) : (
                        <NavLink className="nav-link" to={"/blog/" + blog.name} target="_top">
                            Read More
                        </NavLink>
                        )}
                        </button>
                        {/* <p className="card-date align-items-end text-end col">{textCreatedDate}</p> */}
                    </div>
                </div>
            </div>
            );
        });
        return body;
    };
}

export default Blogs;